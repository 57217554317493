import React, { useState } from 'react';
import '../App.css'; 

const Routes = () => {
  // State to control legend visibility
  const [isVisible, setIsVisible] = useState(false);
  const check = true;

  // Toggle visibility
  const toggleRoutesMenu = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="routes-container">
      {/* Button to toggle the legend visibility */}
      <button className="route-toggle-btn" onClick={toggleRoutesMenu}>
      <img src={'/img/route.svg'} style={{ width: '28px' }} alt={`↑`} />
      </button>

      {check && (
        <div className={`${isVisible ? 'route-menu-visible' : ''}`}>
          <div className='overlay'></div>

          <div className="map-routes">
            <h3>Rovaniemi (EFRO) - Tactical Routings</h3>
            <button className="legend-close-btn" onClick={toggleRoutesMenu}>x</button>
            
            <h4>Scottish (EGPX) through Norway</h4>
            <p className='route-list'>
              ALOTI T400 EGAGO DCT OSLAV DCT BEGDO DCT BESLA DCT MISMO DCT NEMGU
            </p>
            <p className='route-list'>
              BEREP DCT KEKOD DCT OSMUM DCT SOLKA DCT OSS DCT BEGDO DCT BESLA DCT MISMO DCT NEMGU
            </p>
            <p className='route-list'>
              INBOB DCT DANKO M604 SVA M609 ULMUG DCT ELVOM DCT ROGED DCT GEVRU DCT SUN DCT ELBOG DCT BESLA DCT MISMO DCT NEMGU
            </p>
            
            <h4>Amsterdam (EHAA) through Denmark</h4>
            <p className='route-list'>
              GREFI DCT DANKO M604 SVA M609 ULMUG DCT ELVOM DCT ROGED DCT GEVRU DCT SUN DCT ELBOG DCT BESLA DCT MISMO DCT NEMGU
            </p>

            <h4>Warsaw (EPWW) through Baltic or Sweden</h4>
            <p className='route-list'>
              BOKSU DCT GUNTA DCT SOKVA DCT DOBAN DCT TEVRU Y75 NEPIX
            </p>
            <p className='route-list'>
              PENOR DCT KOSKA DCT OTVEG DCT GODOB DCT NEPIX
            </p>

            <h4>Bremen (EDWW) through Warsaw (EPWW) and Sweden</h4>
            <p className='route-list'>
              DEMUR N746 GORPI DCT LATVI DCT ELRID DCT EVLAN DCT OTVEG DCT GODOB DCT NEPIX
            </p>

            <h4>Bremen (EDWW) through Denmark</h4>
            <p className='route-list'>
              EKERN M852 VADIN DCT LABAN N873 ARS DCT BAKLA DCT DIVEG DCT GODOB DCT NEPIX
            </p>
            
            <h4>Polaris (ENOR) - Northern Norway</h4>
            <p className='route-list'>
              GOMAV DCT EXUTI DCT GITEV DCT TUGPU DCT OSLIT
            </p>

            <h4>Reykjavik (BIRD) through Norway</h4>
            <p className='route-list'>
              IPTON DCT EVLED DCT OSMUM DCT SOLKA DCT OSS DCT BEGDO DCT BESLA DCT MISMO DCT NEMGU
            </p>

            <h4>Tallinn (EETT)</h4>
            <p className='route-list'>
              INTOR DCT TEVRU Y75 NEPIX
            </p>

            <h4>St Petersburg (ULLL)</h4>
            <p className='route-list'>
              LEDUN DCT TEVRU Y75 NEPIX
            </p>
            

          </div>
        </div>
      )}
    </div>
  );
};

export default Routes;
