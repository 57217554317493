import React, { useEffect, useState, useRef } from 'react';
import { Source, Layer, Popup, useMap } from 'react-map-gl';

const simulatedFlowData = [
    {
      id: 1,
      ident: "EGTT25B",
      event_id: 44,
      reason: "Due runway capacity",
      starttime: "2022-04-18T13:15:30Z",
      endtime: "2022-04-18T13:15:30Z",
      withdrawn_at: "2022-04-18T13:15:30Z",
      notified_flight_information_regions: [
        10,
        2,
        42
      ],
      measure: {
        type: "mandatory_route",
        value: "INBOB DCT DANKO M604 SVA M609 ULMUG DCT ELVOM DCT ROGED DCT GEVRU DCT SUN DCT ELBOG DCT BESLA DCT MISMO DCT NEMGU"
      },
      filters: [
        {
          type: "ADEP",
          value: [
            "EGKK",
            "EGLL",
            "EGSS"
          ]
        },
        {
          type: "ADES",
          value: [
            "EFRO"
          ]
        },
        {
          type: "level",
          value: [
            230,
            240
          ]
        }
      ]
    },
    {
        id: 1,
        ident: "EGTT25B",
        event_id: 44,
        reason: "Due runway capacity",
        starttime: "2022-04-18T13:15:30Z",
        endtime: "2022-04-18T13:15:30Z",
        withdrawn_at: "2022-04-18T13:15:30Z",
        notified_flight_information_regions: [
          10,
          2,
          42
        ],
        measure: {
          type: "minimum_departure_interval",
          value: 120
        },
        filters: [
          {
            type: "ADEP",
            value: [
              "EGKK",
              "EGLL",
              "EGSS"
            ]
          },
          {
            type: "ADES",
            value: [
              "EH**"
            ]
          },
          {
            type: "level",
            value: [
              230,
              240
            ]
          }
        ]
      }
];
  

const FmpComponent = () => {
  const [flowData, setFlowData] = useState([]);
  const [selectedRectangle, setSelectedRectangle] = useState(null);
  const { current: map } = useMap(); // Access Mapbox map instance
  const mapRef = useRef(null); // Reference to the Mapbox map

  // Example rectangle data (coordinates are just for reference, no bounds needed)
  const rectangleData = [
    {
      id: '22',
      coordinates: [26.7159, 58.3784],
      name: 'Tallinn - EETT'
    },
    {
      id: '29',
      coordinates: [16.9333, 52.4000],
      name: 'Warsaw - EPWW'
    },
    {
      id: '11',
      coordinates: [-4.5000, 59.5000],
      name: 'Scottish - EGPX'
    },
    {
      id: '26',
      coordinates: [9.9500, 52.4000],
      name: 'Bremen - EDWW'
    },
    {
      id: '32',
      coordinates: [4.5000, 52.5000],
      name: 'Amsterdam - EHAA'
    },
    {
        id: '10',
        coordinates: [-3.5, 51],
        name: 'London - EGTT'
      }
  ];

  useEffect(() => {
    const fetchFlowData = () => {
      fetch('https://ecfmp.vatsim.net/api/v1/flow-measure')
        .then(response => response.json())
        .then(data => setFlowData(data))
        .catch(error => console.error('Error fetching flow data', error));
        console.log('Fetching flow data...');
    };
  
    // Fetch the initial flow data
    fetchFlowData();
  
    // Set up polling every 2 minutes
    const interval = setInterval(() => {
      fetchFlowData();
    }, 120000); // 2 minutes in milliseconds
  
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  

  /*
  useEffect(() => {
    // Simulated API call to fetch flow data
    setFlowData(simulatedFlowData);
    console.log(flowData);
  }, []);
  */

  const handleRectangleClick = (event, rectangle) => {
    setSelectedRectangle(rectangle);
  };

  const handleClosePopup = () => {
    setSelectedRectangle(null);
  };

  const getFlowDataForRectangle = (rectangleId) => {
    // Find the flow data for the clicked rectangle, assuming 'id' matches
    const data = flowData.filter(item => item.notified_flight_information_regions.includes(parseInt(rectangleId)));
    return data.length > 0 ? data : null;
  };

  useEffect(() => {
    if (!map) return;

    // Mapbox event listener for hover (pointer cursor)
    rectangleData.forEach((rectangle) => {
      map.on('mouseenter', `rectangle-image-${rectangle.id}`, () => {
        map.getCanvas().style.cursor = 'pointer'; // Change cursor on hover
      });

      map.on('mouseleave', `rectangle-image-${rectangle.id}`, () => {
        map.getCanvas().style.cursor = ''; // Reset cursor when not hovering
      });

      // Handle click event for rectangle
      map.on('click', `rectangle-image-${rectangle.id}`, (e) => {
        handleRectangleClick(e, rectangle);
      });
    });

    return () => {
      rectangleData.forEach((rectangle) => {
        map.off('mouseenter', `rectangle-image-${rectangle.id}`);
        map.off('mouseleave', `rectangle-image-${rectangle.id}`);
        map.off('click', `rectangle-image-${rectangle.id}`);
      });
    };
  }, [map, flowData]);

  const renderFlowData = (flowData) => {
    return flowData.map((flow, index) => {
      const currentTime = new Date();
      const startTime = new Date(flow.starttime);
      const endTime = new Date(flow.endtime);
      const withdrawnTime = flow.withdrawn_at ? new Date(flow.withdrawn_at) : null;
  
      // Helper function to format time in DDHHMMZ format
      const formatToDDHHMMZ = (date) => {
        const utcDay = date.getUTCDate().toString().padStart(2, '0');
        const utcHours = date.getUTCHours().toString().padStart(2, '0');
        const utcMinutes = date.getUTCMinutes().toString().padStart(2, '0');
        return `${utcDay}${utcHours}${utcMinutes}Z`;
      };
  
      // Determine the status class and message
      let timeStatusClass = 'measure-ended';
      let timeStatusMessage = 'Ended';
  
      if (withdrawnTime && currentTime > withdrawnTime) {
        timeStatusClass = 'measure-ended';
        timeStatusMessage = `Withdrawn at ${formatToDDHHMMZ(withdrawnTime)}`;
      } else if (currentTime < startTime) {
        timeStatusClass = 'measure-planned';
        timeStatusMessage = `Planned from ${formatToDDHHMMZ(startTime)}`;
      } else if (currentTime >= startTime && currentTime <= endTime) {
        timeStatusClass = 'measure-active';
        timeStatusMessage = `Active until ${formatToDDHHMMZ(endTime)}`;
      }
  
      // Function to format the measure type
      const formatMeasureType = (measureType) => {
        switch (measureType) {
          case 'minimum_departure_interval':
            return 'Tactical MDI';
          case 'miles_in_trail':
            return 'Miles in Trail';
          case 'mandatory_route':
            return 'Mandatory Route';
          default:
            return measureType
              .replace(/_/g, ' ')
              .replace(/\b\w/g, char => char.toUpperCase());
        }
      };
  
      // Function to format MDI value (convert from seconds to minutes)
      const formatMDIValue = (value) => {
        if (value && !isNaN(value)) {
          return (value / 60).toFixed(1) + " min";
        }
        return 'N/A';
      };
  
      return (
        <div key={index} className="flow-measure">
          <div className="field">
            <strong className='tactical-measure-text'>Tactical Measure</strong>
            <div className={timeStatusClass}>{timeStatusMessage}</div>
            <div className="value value-measure-type">
              {flow.measure?.type ? formatMeasureType(flow.measure.type) : 'N/A'}
            </div>
            <div className="value">
              {flow.measure?.type === 'minimum_departure_interval'
                ? formatMDIValue(flow.measure.value)
                : flow.measure?.value || 'N/A'}
            </div>
          </div>
          <div className="field">
            <strong>Reason</strong>
            <div className="value">{flow.reason || 'N/A'}</div>
          </div>
          {flow.filters?.map((filter, idx) => (
            <div key={idx} className="field">
              <strong>{filter.type}</strong>
              <div className="value">{filter.value?.join(', ') || 'N/A'}</div>
            </div>
          ))}
        </div>
      );
    });
  };
  
  
  const getMeasureCountForRectangle = (rectangleId) => {
    // Get the number of measures for the rectangle
    const data = getFlowDataForRectangle(rectangleId);
    return data ? data.length : 0;
  };

  return (
    <div>
      <p>Map with flow data</p>

      {/* Loop through rectangleData to add each rectangle to the map */}
      {rectangleData.map((rectangle) => (
        <Source
          key={rectangle.id}
          id={`rectangle-source-${rectangle.id}`}
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',  // Using Point instead of Polygon for the center point
                  coordinates: rectangle.coordinates,
                },
                properties: {
                  id: rectangle.id,
                  name: rectangle.name
                },
              },
            ],
          }}
        >
          <Layer
            id={`rectangle-image-${rectangle.id}`}
            type="symbol"
            layout={{
                'icon-image': 'ecfmp-logo-3',
                'icon-size': 0.15,
                'icon-anchor': 'center',
                'icon-allow-overlap': true,
                'text-allow-overlap': true
            }}
            minzoom={3}
          />
        </Source>
      ))}

      {/* Popup information */}
      {selectedRectangle && (
        <Popup
          longitude={selectedRectangle.coordinates[0]}
          latitude={selectedRectangle.coordinates[1]}
          closeButton={true}
          closeOnClick={false}
          anchor="top"
          onClose={handleClosePopup}
        >
            <div className='fmp-title-container'>
                <h3 className='fmp-title'>{selectedRectangle.name}</h3>
                <span className='fmp-count'>({getMeasureCountForRectangle(selectedRectangle.id)})</span>
            </div>
         
            <div className="fmp-popup-content">
                {getFlowDataForRectangle(selectedRectangle.id) ? (
                renderFlowData(getFlowDataForRectangle(selectedRectangle.id))
                ) : (
                <p className='fmp-disclamer'>No flow data available for this region</p>
                )}
            </div>
        </Popup>
      )}
    </div>
  );
};

export default FmpComponent;
